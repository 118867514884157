
import React, { useState, useEffect } from 'react';
import './styles.css';
import { Button, Select, Table, Form, Input, InputNumber, notification, Collapse, Modal, Spin } from 'antd';
import * as SecureLS from "secure-ls";
import dayjs from 'dayjs';
import axios from 'axios';
import { useSearchParams, useLocation } from "react-router-dom";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import { TruckOutlined } from '@ant-design/icons';
import _ from "lodash";
import { constant } from '../../../core_units/constant';
import { FaInfoCircle } from "react-icons/fa";
import FindTeacherStudent from '../../FindTeacherStudent/FindTeacherStudent';
import Courselist from './courselist';
const { Panel } = Collapse;
const { Option } = Select;
const PackageList = (props) => {
  console.log(props, "0909==")

  const [packageList, setPackageList] = useState([]);
  var ls = new SecureLS({ encodingType: 'aes' });
  const loginDetails = ls.get('isLogin');
  const mixpanel = require('mixpanel-browser');
  const [searchParam] = useSearchParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEPModalVisible, setEPModalVisible] = useState(false);
  const [isFTModalVisible, setIsFTModalVisible] = useState(false);
  const [ftIndex, setFTIndex] = useState(null);
  const [isTimeModalVisible, setIsTimeModalVisible] = useState(false);
  const [courseD, setCourseD] = useState();
  const [timeCourseD, setTimeCourseD] = useState();
  const [selectedDemoCourseId, setSelectedDemoCourseId] = useState(null);
  const [selectedTimeDemoCourseId, setSelectedTimeDemoCourseId] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [recomentedCourseDetail, setRecomentedCourseDetail] = useState('');
  const [recomentedTimeCourseDetail, setRecomentedTimeCourseDetail] = useState('');
  const [overallFeedbackData, setOverallFeedbackData] = useState();
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedDropTeacherId, setSelectedDropTeacherId] = useState(null);
  const [teacherList, setTeacherList] = useState([]);
  const [teacherDropList, setTeacherDropList] = useState([]);
  const [courseSelected, setCourseSelected] = useState(false);
  const [teacherSelected, setTeacherSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTeacherDropdown, setShowTeacherDropdown] = useState(false);
  const [showDayDrop, setShowDayDrop] = useState(false);
  const [showTimeDrop, setShowTimeDrop] = useState(false);
  const [teacherDropdownClicked, setTeacherDropdownClicked] = useState(false);
  const [selectedTeacherIds, setSelectedTeacherIds] = useState({});
  const [teacherDropdownVisibleMap, setTeacherDropdownVisibleMap] = useState({});
  const studentId = searchParam.get("id");
  const [prefDay, setPrefDay] = useState(false);
  const [prefTime, setPrefTime] = useState(false);
  const [fileDay, setFileDay] = useState("");
  const [file, setFile] = useState("");
  const [dropFileDay, setDropFileDay] = useState("");
  const [dropFile, setDropFile] = useState("");
  const [selectArray, setSelectArray] = useState();
  const [classSchedule, setClassSchedule] = useState();
  const [allCourseList, setAllCourseList] = useState();
  const [walletDetail, setWalletDetail] = useState();
  const [packageLoading, setPackageLoading] = useState(false);
  const artiumCashReduction = {
    redemptionRules: [
      {
        "count": 36,
        "INRamount": 5000,
        "USDamount": 125
      },
      {
        "count": 24,
        "INRamount": 2000,
        "USDamount": 75
      },
      {
        "count": 12,
        "INRamount": 500,
        "USDamount": 25
      }
    ], classLimit: 4
  };
  const calculateDifference = (packageDetails) => {
    const currentDate = dayjs();
    const targetDate = dayjs(packageDetails?.classDateTime).add(1, 'h');
    const diffInMilliseconds = targetDate.diff(currentDate, 'millisecond');
    return (diffInMilliseconds >= 0 ? diffInMilliseconds : 0);
  };
  useEffect(async () => {
    init();
    getArtiumCash().then(c => {
      if (c?.data) {
        setWalletDetail(c?.data);
      }
    });
  }, [])
  const childFunc = React.useRef(null);
  const init = async () => {
    let cList = [];
    props.students.courseList.courses.forEach(element => {
      element.tags.forEach(ele => {
        ele.groupId = element.id;
        ele.category = element.category;
        if (ele.name) {
          ele.courseName = `${element.name} - ${ele.name}`
        } else {
          ele.courseName = `${element.name}`
        }
        cList.push(ele);
      });
    });
    setAllCourseList(cList);
    setLoading(true);
    try {
      const response = await getPackageList();
      if (response?.data?.length) {
        const activeArray = [];
        const sortedData = response?.data.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
        sortedData.forEach(e => {
          if (activeArray.indexOf(e.courseId) == -1) {
            activeArray.push(e.courseId)
            e.active = true;
          } else {
            e.active = false;
          }
        })
        setPackageList(sortedData);
        console.log(sortedData, "sortedData==")
      }
    } catch (error) {
      console.error('Error fetching package list:', error);
    } finally {
      setLoading(false);
    }
  };
  const getArtiumCash = () => {
    return axios.get(`${appEnvirontVariables.API.REFERRAL_URL}/api/artium-cash/${props.profileDetails.userId}?userType=${props.profileDetails.userType.toLowerCase()}`,
      { headers: { "Authorization": loginDetails['authorization'] } });
  }
  const getPackageList = () => {
    return axios.get(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/student-onboarding-package/${studentId}`,
      { headers: { "Authorization": loginDetails['authorization'] } });
  }
  const getClassDetail = (studentId, courseId) => {
    //https://uat.artiumacademy.com:8765/api/admin/studentclass/51748?size=100&page=0&studentId=51748&courseId=45
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/studentclass/${studentId}?size=100&page=0&studentId=${studentId}&courseId=${courseId}`,
      { headers: { "Authorization": loginDetails['authorization'] } });
  }
  const getRecomentedCourse = (classId, teacherCredentialId) => {
    //https://uat.artiumacademy.com:8765/api/course/feedbacks?classId=93965&userId=44193
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/course/feedbacks?classId=${classId}&userId=${teacherCredentialId}`,
      { headers: { "Authorization": loginDetails['authorization'] } });
  }
  const handleSpecialDiscountChange = (newValue, index, i) => {
    if (newValue == " " || !isNaN(newValue)) {
      const updatedPackageList = [...packageList];
      updatedPackageList[index].planList[i].specialDiscountPercentage = newValue;
      setPackageList(updatedPackageList);
    }
  };
  const handleFlashDiscountChange = (newValue, index, i) => {
    if (newValue == " " || !isNaN(newValue)) {
      const updatedPackageList = [...packageList];
      updatedPackageList[index].planList[i].flashDiscountPercentage = newValue;
      setPackageList(updatedPackageList);
    }
  };
  const handleAdditionalDiscountChange = (newValue, index, i) => {
    if (newValue == " " || !isNaN(newValue)) {
      const updatedPackageList = [...packageList];

      updatedPackageList[index].planList[i].additionalDiscountPercentage = newValue;
      //updatedPackageList[index].planList[i].flashDiscountPercentage = +newValue ? 0 : 3;

      setPackageList(updatedPackageList);
    }
  };

  const handleClassCount = (newValue, courseId, index, i) => {
    if (newValue == " " || !isNaN(newValue)) {
      const updatedPackageList = [...packageList];
      updatedPackageList[index].planList[i].sessionCounts[courseId] = +newValue;
      let total = 0;
      updatedPackageList[index].planList[i].courseIds.forEach(cid => {
        total += +(+updatedPackageList[index].planList[i].sessionCounts[cid] * +updatedPackageList[index].courseData.find(e => e.courseId == cid).unit_price);
      })
      updatedPackageList[index].planList[i].total = +total.toFixed(2);
      updatedPackageList[index].planList[i].discountedTotal = +(updatedPackageList[index].planList[i].total - (updatedPackageList[index].planList[i].total * (updatedPackageList[index].planList[i].discountPercentage / 100))).toFixed(2);
      updatedPackageList[index].planList[i].classCount = Object.values(updatedPackageList[index].planList[i].sessionCounts).reduce((acc, count) => acc + +count, 0);
      debugger;
      if (walletDetail.totalArtiumCash) {
        let refData = referalDeductionDetails(walletDetail, updatedPackageList[index].planList[i]);
        updatedPackageList[index].planList[i] = { ...updatedPackageList[index].planList[i], ...refData };
        if (+refData.walletAmount >= +updatedPackageList[index].planList[i].discountedTotal) {
          updatedPackageList[index].planList[i].walletAmount = +updatedPackageList[index].planList[i].discountedTotal;
          updatedPackageList[index].planList[i].walletLabel = updatedPackageList[index].planList[i].walletAmount ? `${updatedPackageList[index].courseData[0].currency == 'INR' ? '₹' : 'US$'}${(+updatedPackageList[index].planList[i].walletAmount.toFixed(2)).toLocaleString()} wallet balance applicable` : "";
        } else {
          updatedPackageList[index].planList[i].walletAmount = +refData.walletAmount;
          updatedPackageList[index].planList[i].walletLabel = refData.walletAmount ? `${updatedPackageList[index].courseData[0].currency == 'INR' ? '₹' : 'US$'}${(+refData.walletAmount.toFixed(2)).toLocaleString()} wallet balance applicable` : "";
        }
      }
      updatedPackageList[index].planList[i].sessionCounts[courseId] = newValue;
      updatedPackageList[index].planList[i].sessionPrice = (+(updatedPackageList[index].planList[i].total - (updatedPackageList[index].planList[i].total * (updatedPackageList[index].planList[i].specialDiscountPercentage / 100))) / +updatedPackageList[index].planList[i].classCount).toFixed(2);
      setPackageList(updatedPackageList);
    }
  }
  const referalDeductionDetails = (referralData, plan) => {
    let deductionAmount = 0;
    let mycash = 0;
    let fRedemtionAmount = 0;
    let total = +plan.discountedTotal;
    if (referralData.totalArtiumCash) {
      const artiumCashAmount = (+referralData.totalArtiumCash) * (+referralData[`${plan.currency}PointConversion`]);
      const redemtionRule = artiumCashReduction?.redemptionRules.find((item) => +plan.classCount >= item.count);
      if (redemtionRule) {
        fRedemtionAmount = +artiumCashAmount >= redemtionRule[`${plan.currency}amount`] ? +redemtionRule[`${plan.currency}amount`] : (+artiumCashAmount || 0);
        deductionAmount += (+fRedemtionAmount);
        if (+deductionAmount >= total) {
          deductionAmount = total;
          total = 0;
        }
      }
    }
    if (referralData.myCash) {
      mycash = (+referralData.myCash) * (+referralData[`${plan.currency}PointConversion`]);
      mycash = +mycash >= +total ? +total : mycash;
      deductionAmount += +mycash;
    }
    return {
      walletAmount: deductionAmount,
      artiumCash: fRedemtionAmount || 0,
      myCash: mycash || 0,
      artiumCashPoint: +referralData?.totalArtiumCash || 0,
      myCashPoint: +referralData?.myCash || 0,
    };
  }

  const handleOpen = () => {
    //const studentCourseData = props.studentcourse.filter(course => course.demoCourse !== "false");
    const studentCourseData = allCourseList.filter(e => !props.studentcourse.find(course => (course.demoCourse == "false" && course.courseId == e.paidCourseId)));
    setCourseD(studentCourseData);
    console.log(studentCourseData, "studentCourseData==");
    if (studentCourseData.length) {
      setIsModalVisible(true);
    } else {
      notification.error({
        message: "",
        description: `Package can create only for Demo Course.`,
      });
    }

  };
  const handleTimeModalOpen = () => {
    const studentCourseData = props.studentcourse.filter(course => course.demoCourse !== "false");
    setTimeCourseD(studentCourseData);
    if (studentCourseData.length) {
      handleTimeCourseChange(studentCourseData[0].tagId);
    }

    console.log(studentCourseData, "studentCourseData==");
    if (studentCourseData.length) {
      setIsTimeModalVisible(true);
    } else {
      notification.error({
        message: "",
        description: `Package is not there to update Preferred Time.`,
      });
    }

  };
  const handleCancel = () => {
    setSelectedCourseId("");
    setSelectedDemoCourseId("");
    setSelectedTeacherId("");
    setTeacherSelected("");
    setCourseSelected("");
    setFile("");
    setFileDay("");
    setIsModalVisible(false);
  };
  const handleFTCancel = () => {
    setIsFTModalVisible(false);
  };
  const handleTimeModalCancel = () => {
    setSelectedCourseId("");
    setSelectedTimeDemoCourseId("");
    setSelectedTeacherId("");
    setTeacherSelected("");
    setCourseSelected("");
    setFile("");
    setFileDay("");
    setRecomentedTimeCourseDetail("")
    setIsTimeModalVisible(false);
  }
  const handleCourseChange = async (value) => {
    //setSelectedDemoCourseId(value);
    setSelectedCourseId(value);
    setTeacherList([]);
    fetchTeachers(value);
    return;
    let cId;
    setRecomentedCourseDetail('');
    setSelectedCourseId('');
    setSelectedTeacherId('');
    setTeacherList([]);
    props.students.courseList.courses.forEach(element => {
      element.tags.forEach(ele => {
        if (ele.id == value) {
          cId = ele.paidCourseId;
          setSelectedTeacherId(null);
          setSelectedCourseId(cId);
          fetchTeachers(ele.paidCourseId);
        }
      });
    });
    if (cId) {
      let classDetails = await getClassDetail(studentId, cId);
      if (classDetails.data.content && classDetails.data.content[0] && classDetails.data.content[0].teacherCredentialId) {
        let recomentedCourseDetails = await getRecomentedCourse(classDetails.data.content[0].classScheduleId, classDetails.data.content[0].teacherCredentialId);
        if (recomentedCourseDetails?.data && recomentedCourseDetails.data.length) {
          const rcName = recomentedCourseDetails.data[0].feedbacks.find(a => a.question == "What course do you recommend?").option;
          const rcId = recomentedCourseDetails.data[0].feedbacks.find(a => a.question == "courseId").option;

          let pCourse = props.studentcourse.find(e => e.courseId == rcId);
          if (!(pCourse && pCourse.demoCourse == "false")) {
            let pDT = recomentedCourseDetails.data[0].feedbacks.find(e => e.questionId == "14").option;
            if (pDT) {
              setFile(pDT.split("-")[1]);
              setFileDay(pDT.split("-")[0]);
            }
            setRecomentedCourseDetail({ courseId: rcId, courseName: rcName });
          }
        }
      }
    }
  }
  const handleTimeCourseChange = async (value) => {
    setSelectedTimeDemoCourseId(value);
    console.log(value, "value====timeCourse")
    const selectedCourseArray = timeCourseD.find(course => course.tagId === value);
    setSelectArray(selectedCourseArray);

    console.log("Selected Course Array:", selectedCourseArray);
    let cId;
    setOverallFeedbackData('');
    // setSelectedCourseId('');
    // setSelectedTeacherId('');
    setTeacherList([]);
    props.students.courseList.courses.forEach(element => {
      element.tags.forEach(ele => {
        if (ele.id == value) {
          cId = ele.paidCourseId;
          // setSelectedTeacherId(null); 
          // setSelectedCourseId(cId);
          // fetchTeachers(ele.paidCourseId);
        }
      });
    });
    if (cId) {
      let classDetails = await getClassDetail(studentId, cId);
      if (classDetails.data.content && classDetails.data.content[0] && classDetails.data.content[0].teacherCredentialId) {
        const selectedClassCourseArray = classDetails.data.content[0];
        setClassSchedule(selectedClassCourseArray);
        console.log(selectedClassCourseArray, "selectedClassCourseArray==")
        let recomentedCourseDetails = await getRecomentedCourse(classDetails.data.content[0].classScheduleId, classDetails.data.content[0].teacherCredentialId);
        if (recomentedCourseDetails?.data && recomentedCourseDetails.data.length) {
          const rcName = recomentedCourseDetails.data[0].feedbacks.find(a => a.question == "What course do you recommend?").option;
          const rcId = recomentedCourseDetails.data[0].feedbacks.find(a => a.question == "courseId").option;
          let pDT = recomentedCourseDetails.data[0].feedbacks.find(e => e.question == "Preferred Day & Timing?").option;
          setOverallFeedbackData(recomentedCourseDetails.data[0])
          console.log(recomentedCourseDetails.data[0], "recTimeDetails==")
          if (pDT) {
            console.log(pDT, "pDT===")
            setFile(pDT.split("-")[1]);
            setFileDay(pDT.split("-")[0]);
          }
          let pCourse = props.studentcourse.find(e => e.courseId == rcId);
          if (!(pCourse && pCourse.demoCourse == "false")) {

            setRecomentedTimeCourseDetail({ courseId: rcId, courseName: rcName });
          }
        }
      }
    }
  }
  const handleCopyLink = (payLink) => {
    navigator.clipboard
      .writeText(payLink)
      .then(() => {
        notification.success({
          message: 'Copied successfully',
          placement: 'topRight',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          placement: 'topRight',
        });
      });
  };

  const savePackage = (studentPackage) => {
    setPackageLoading(true);
    if (studentPackage.freeCourse && studentPackage.freeCourse.classesToPurchase) {
      studentPackage.freeCourse.classesToPurchase = +studentPackage.freeCourse.classesToPurchase;
    }
    if (studentPackage.freeCourse && !studentPackage.freeCourse.classesToPurchase) {
      notification.error({
        message: 'Please add class count for free course',
        placement: 'topRight',
      });
      setPackageLoading(false);
      return;
    }
    if (!studentPackage.expiryDate) {
      notification.error({
        message: 'Please add expiry date.',
        placement: 'topRight',
      });
      setPackageLoading(false);
      return;
    }
    studentPackage.updatedBy = loginDetails?.studentName || "";
    studentPackage.token = loginDetails['authorization'];
    delete studentPackage.teacherList;
    studentPackage.planList.forEach(e => {
      e.specialDiscountPercentage = +e.specialDiscountPercentage || 0;
      e.additionalDiscountPercentage = +e.additionalDiscountPercentage || 0;
      e.flashDiscountPercentage = +e.flashDiscountPercentage || 0;
      e.courseIds.forEach(z => e.sessionCounts[z] = +e.sessionCounts[z] || 0);
    });
    // let preferredDayTime = "";
    // if (dropFileDay && dropFile) {
    //     preferredDayTime = `${dropFileDay}-${dropFile}`;
    // } else if (dropFileDay) {
    //     preferredDayTime = dropFileDay;
    // } else if (dropFile) {
    //     preferredDayTime = dropFile;
    // }
    // studentPackage.preferredDayTime = preferredDayTime;
    axios.put(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/onboarding-package`, studentPackage,
      {
        headers: {
          Authorization: loginDetails['authorization']
        }
      }
    )
      .then(response => {
        console.log('Package created successfully:', response.data);
        //init();
        notification.success({
          message: 'Package updated successfully.',
          placement: 'topRight',
        });
        setPackageLoading(false);
      })
      .catch(error => {
        setPackageLoading(false);
        console.error('Error creating package:', error);
      });
  }

  const fetchTeachers = (courseId) => {
    axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/teachers?courseId=${courseId}`, {
      courseId: courseId,
      page: 0,
      size: 500
    },
      {
        headers: {
          Authorization: loginDetails['authorization']
        }
      }
    )
      .then(response => {
        setTeacherList(response.data.content);
        console.log(response.data.content, "response.data==")
      })
      .catch(error => {
        console.error('Error fetching teachers:', error);
      });
  }
  const handleOk = () => {

    // if (!selectedCourseId) {
    //   setCourseSelected(true);
    // }
    // if (!selectedTeacherId) {
    //   setTeacherSelected(true);
    // }

    if (selectedCourseId) {
      setLoading(true);
      const data = {
        name: props.profileDetails.firstName + " " + props.profileDetails.lastName,
        email: props.profileDetails.emailId,
        teacherId: selectedTeacherId,
        contact: props.profileDetails.mobileNumber,
        state: props.profileDetails.state,
        gender: props.profileDetails.gender,
        ageGroup: props.profileDetails.ageGroup,
        createdBy: loginDetails?.studentName || "",
        courseId: selectedCourseId,
        teacherName: teacherList.find(e => e.teacherId == selectedTeacherId)?.teacherName || "",
        token: loginDetails['authorization'],
        userId: props.profileDetails.userId,
        override: true,
        studentId: props.profileDetails.studentId,
        userType: props.profileDetails.userType,
        countryCode: props.profileDetails.countryCode,
        timeZone: props.profileDetails.timezone,


      };

      if (recomentedCourseDetail) {
        const dropdown = document.getElementById("courseDD");
        const selectedOption = dropdown.options[dropdown.selectedIndex];
        const selectedName = selectedOption.text;
        data.courseId = recomentedCourseDetail.courseId;
        data.demoBookedCourseId = selectedCourseId;
        data.demoBookedCourseName = selectedName;
      }

      axios.post(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/onboarding-package`, data,
        {
          headers: {
            Authorization: loginDetails['authorization']
          }
        }
      )
        .then(response => {
          setLoading(false);
          console.log('Package created successfully:', response.data);
          init();
        })
        .catch(error => {
          setLoading(false);
          console.error('Error creating package:', error);
        });

      handleCancel();
    }
  };
  const handleTimeMOdalOk = () => {
    let oldPTValue;
    if (!selectedTimeDemoCourseId) {
      setCourseSelected(true);
    }
    const preferredDayTime = `${fileDay}-${file}`;
    let data;

    if (selectedTimeDemoCourseId) {
      if (overallFeedbackData) {
        oldPTValue =
          data = {
            ...overallFeedbackData,
            feedbacks: overallFeedbackData.feedbacks.map(feedback => {
              if (feedback.question === "Preferred Day & Timing?") {
                oldPTValue = feedback.option;
                return {
                  ...feedback,
                  option: preferredDayTime
                };
              }
              return feedback;
            })
          };
      } else {
        data =
        {
          classId: classSchedule.classScheduleId,
          studentId: selectArray.studentId,
          teacherId: selectArray.teacherId,
          feedbacks: [
            {
              question: "Preferred Day & Timing?",
              option: preferredDayTime,
              optionValue: "1",
              filePaths: [],
              questionId: "7"
            }
          ],
          teacherName: selectArray.teacherFirstName,
          studentName: props.profileDetails.firstName,
          userId: classSchedule.teacherCredentialId,
          userRole: "Teacher",
          classType: "DEMO"
        }
      }
      axios.post(`${appEnvirontVariables.API.BASE_URL}/api/course/feedback`, data,
        {
          headers: {
            Authorization: loginDetails['authorization']
          }
        }
      )
        .then(response => {
          setLoading(false);
          console.log('Package created successfully:', response.data);
          notification.success({
            message: 'Preferred Day & Time added successfully',

          });
          mixpanel.track(constant.MIXPANEL_PREFERRED_DAY_TIME, {
            LoginUser: loginDetails?.studentName,
            studentId: selectArray.studentId,
            teacherId: selectArray.teacherId,
            teacherName: selectArray.teacherFirstName,
            studentName: props.profileDetails.firstName,
            courseName: selectArray.courseName,
            previousPreferredDayTime: oldPTValue || "",
            preferredDayTime: preferredDayTime,
            recomentedCourse: recomentedTimeCourseDetail.courseName || "",
            feedbackJson: data
          });
        })
        .catch(error => {
          setLoading(false);
          console.error('Error creating package:', error);
        });

      handleTimeModalCancel();
    }
  }
  const handleTeacherOpen = (index) => {
    axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/teachers?courseId=${packageList[index].courseId}`, {
      courseId: packageList[index].courseId,
      page: 0,
      size: 500
    },
      {
        headers: {
          Authorization: loginDetails['authorization']
        }
      })
      .then(response => {
        console.log("Teacher data received:", response.data.content);
        const clonePackage = [...[], ...packageList];
        clonePackage[index].teacherList = response.data.content
        setPackageList(clonePackage);
        console.log("Updated teacherDropList:", response.data.content);
        setLoading(false);

        setShowTeacherDropdown(true);
      })

      .catch(error => {
        console.error('Error fetching teachers:', error);
        setLoading(false);
      });
  }
  const handleDayOpen = () => {
    setShowDayDrop(true)
  }
  const handleTimeOpen = () => {
    setShowTimeDrop(true)
  }
  const getSelectedTeacherName = (packageId) => {
    const selectedTeacherId = selectedTeacherIds[packageId];
    const selectedTeacher = teacherList.find((teacher) => teacher.teacherId === selectedTeacherId);
    console.log("Selected teacher ID:", selectedTeacherId);
    console.log("Selected teacher:", selectedTeacher);
    return selectedTeacher ? selectedTeacher.teacherName : "";
  }

  const removePlan = (packageIndex, planIndex) => {
    const clonePackage = [...[], ...packageList];
    if (clonePackage[packageIndex].planList.filter(e => e.remove).length < (clonePackage[packageIndex].planList.length - 1) || clonePackage[packageIndex].planList[planIndex].remove) {
      clonePackage[packageIndex].planList[planIndex].remove = !clonePackage[packageIndex].planList[planIndex].remove;
      if (clonePackage[packageIndex].planList.filter(e => e.remove).length > 1) {
        clonePackage[packageIndex].freeCourse = null;
      }
      setPackageList(clonePackage);
    } else {
      notification.error({
        message: 'Can not remove all the plan from package.',
        placement: 'topRight',
      });
    }

  }
  const getArtiumAccountDetail = (courseId) => {
    return axios.get(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/account-detail/${courseId}`,
      { headers: { "Authorization": loginDetails['authorization'] } });
  }
  const addFreeCourse = async (e, index) => {
    let filteredPlan = packageList[index].planList.filter(e => !e.remove);
    if (filteredPlan.length > 1) {
      notification.warning({
        message: 'Please remove all other plan except any one to add free cousre',
        placement: 'topRight',
      })
    } else {
      if (e.target.value) {
        if (filteredPlan[0].courseIds.indexOf(e.target.value) > -1) {
          notification.warning({
            message: 'Course already added in package list',
            placement: 'topRight',
          });
          return;
        }
        const clonePackage = [...[], ...packageList];
        const selectedCourse = allCourseList.find(z => z.paidCourseId == e.target.value);
        let accountDetail = "";
        const accountDetailResponse = await getArtiumAccountDetail(e.target.value);
        if (accountDetailResponse.data && accountDetailResponse.data.data) {
          accountDetail = accountDetailResponse.data.data;
        }
        clonePackage[index].freeCourse = {
          "courseName": selectedCourse.courseName,
          "teacherId": "",
          "classesToPurchase": 0,
          "discountedPrice": 0,
          "unit_price": 0,
          "pricePerClass": 0,
          "amountBeforeTax": 0,
          "category": selectedCourse.category || "",
          "gst": 0,
          "account_id": accountDetail.account_id,
          "account_id_prod": accountDetail.account_id,
          "account_id_uat": accountDetail.account_id,
          "item_id": accountDetail.item_id_free,
          "item_id_prod": accountDetail.item_id_free,
          "item_id_uat": accountDetail.item_id_free,
          "level": selectedCourse.localizedName,
          "discountedPercentage": 0,
          "courseId": selectedCourse.paidCourseId,
          "trialTeacher": "",
          "price": 0,
          "isFree": true
        }
        setPackageList(clonePackage);
      } else {
        const clonePackage = [...[], ...packageList];
        clonePackage[index].freeCourse = null;
        setPackageList(clonePackage);
      }
    }
  }
  const addFreeClassCount = (e, index) => {
    const clonePackage = [...[], ...packageList];
    clonePackage[index].freeCourse.classesToPurchase = e.target.value || "";
    setPackageList(clonePackage);
  }

  const assignTeacher = (e, index) => {
    setIsFTModalVisible(true);
    setFTIndex(index);
  }
  const assignTeacherDetails = (item) => {
    const clonePackage = [...[], ...packageList];
    clonePackage[ftIndex].teacherId = item.id;
    clonePackage[ftIndex].teacherName = item.userDetails.firstName;
    clonePackage[ftIndex].courseData.forEach(e => e.teacherId = item.id);
    setPackageList(clonePackage);
  }

  const handleExpiryData = (e,index) => {
    const clonePackage = [...[], ...packageList];
    clonePackage[index].expiryDate = e.target.value;
    setPackageList(clonePackage);
  }

  const handleUseWallet = (event, index) => {
    const updatedPackageList = [...[], ...packageList];
    updatedPackageList[index].removeWallet = !event.target.checked;
    if(event.target.checked){
      if (walletDetail.totalArtiumCash) {
        updatedPackageList[index].planList.forEach((v,i) => {
          let refData = referalDeductionDetails(walletDetail, updatedPackageList[index].planList[i]);
        updatedPackageList[index].planList[i] = { ...updatedPackageList[index].planList[i], ...refData };
        if (+refData.walletAmount >= +updatedPackageList[index].planList[i].discountedTotal) {
          updatedPackageList[index].planList[i].walletAmount = +updatedPackageList[index].planList[i].discountedTotal;
          updatedPackageList[index].planList[i].walletLabel = updatedPackageList[index].planList[i].walletAmount ? `${updatedPackageList[index].courseData[0].currency == 'INR' ? '₹' : 'US$'}${(+updatedPackageList[index].planList[i].walletAmount.toFixed(2)).toLocaleString()} wallet balance applicable` : "";
        } else {
          updatedPackageList[index].planList[i].walletAmount = +refData.walletAmount;
          updatedPackageList[index].planList[i].walletLabel = refData.walletAmount ? `${updatedPackageList[index].courseData[0].currency == 'INR' ? '₹' : 'US$'}${(+refData.walletAmount.toFixed(2)).toLocaleString()} wallet balance applicable` : "";
        }
        });        
      }
    }else{
      updatedPackageList[index].planList.forEach((v,i) => { 
        updatedPackageList[index].planList[i].walletAmount = 0;
        updatedPackageList[index].planList[i].walletLabel = "";
        updatedPackageList[index].planList[i].myCash = 0;
        updatedPackageList[index].planList[i].artiumCash = 0;
      });
    }
    setPackageList(updatedPackageList);
  }
  return (<div>
    <h4>Package List</h4>
    <div>
      <div style={{ overflow: "auto", textAlign: "right" }}>
        
        <Button type="primary" style={{ display: "flex", float: "right", marginLeft: "10px" }} onClick={handleOpen}>
          Create Package
        </Button>
        <Button type="primary" style={{ display: "flex", float: "right", marginLeft: "10px" }} onClick={() => {setEPModalVisible(true)}}>
          Record External Payment
        </Button>
        <Button type="primary" style={{ display: "flex", float: "right" }} onClick={handleTimeModalOpen}>
          Update Preferred Day & Time
        </Button>
        
        {walletDetail && (+walletDetail?.myCash || +walletDetail?.totalArtiumCash) ? <span className='walletText'>Available Wallet Amount : {!(props.profileDetails.timezone == 'Asia/Kolkata' || props.profileDetails.timezone == 'Asia/Calcutta') ? "US$" : "₹"} {(+walletDetail?.myCash + +walletDetail?.totalArtiumCash)} (My Cash : {!(props.profileDetails.timezone == 'Asia/Kolkata' || props.profileDetails.timezone == 'Asia/Calcutta') ? "US$" : "₹"}{+walletDetail?.myCash} + Artium Cash : {!(props.profileDetails.timezone == 'Asia/Kolkata' || props.profileDetails.timezone == 'Asia/Calcutta') ? "US$" : "₹"}{+walletDetail?.totalArtiumCash})</span> : ""}
      </div>
      {isFTModalVisible && <div id="myftModal" className="ftmodal">
        <div className="ft-modal-content">
          <div className="ft-modal-header">
            <span onClick={handleFTCancel} className="ftclose">&times;</span>
            <h5>Find Teacher</h5>
          </div>
          <div className="ft-modal-body">
            <div className='FTContainer' style={{ overflow: "auto" }}>
              {isFTModalVisible && <FindTeacherStudent handleFTCancel={handleFTCancel} assignTeacher={assignTeacherDetails} fromPackage={true} profileDetails={props.profileDetails} />}
            </div>
          </div>
        </div>
      </div>}
      <Modal title="Mark External Payment" width={1150}
        visible={isEPModalVisible}
        onOk={() => {
          setEPModalVisible(false);
        }}
        onCancel={() => {
          setEPModalVisible(false);
          childFunc.current();        
        }}
        footer={null}>
        <div className='new-payment-form' style={{width: "100%", overflow:"auto"}}>
           <Courselist childFunc={childFunc} onlyExternal={true} closemodal={() => {setEPModalVisible(false);}} viewProfile={props.students.viewprofile} isOffline={props.isOffline} profile={{ ...props.students.viewprofile, ...{credId: searchParam.get("credid")}, ...{token : loginDetails}}} />   
        </div>
      </Modal>
      {/* <Modal
        title="Find Teacher"
        visible={isFTModalVisible}
        onOk= {null}
        onCancel={handleFTCancel}
        footer={null}
        width={900}
      >
        <div className='FTContainer' style={{overflow:"auto"}}>
        {isFTModalVisible && <FindTeacherStudent profileDetails={props.profileDetails} />}
        </div>
      </Modal> */}
      <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Create Package"
      >
        {/* {courseD.length > 0 ? (
    <div></div>
  ) : (
    <div>No demo courses available to create packages</div>
  )} */}
        {loading ? (
          <Spin size="large" style={{ display: "flex", justifyContent: "center" }} />
        ) : (
          <>
            {courseD && courseD.length > 0 ? (
              <div>
                <div>
                  <label>Course</label>
                  : <select style={{ width: "50%" }} id="courseDD" onChange={(e) => handleCourseChange(e.target.value)}
                    value={selectedCourseId}>
                    <option value="">Select a course</option>
                    {courseD && courseD.length > 0 && (
                      courseD.map((course, i) => (
                        <option value={course.paidCourseId} key={i}>
                          {course.courseName}
                        </option>
                      ))
                    )}
                  </select>
                  {recomentedCourseDetail ? <div><span><label>Recomented Course: </label> {recomentedCourseDetail.courseName}</span></div> : ""}
                  {courseSelected && !selectedCourseId && (
                    <div style={{ color: "red", marginBottom: "12px", marginLeft: "-73px", display: "flex", justifyContent: "center" }}>Please select a course</div>
                  )}

                  {console.log("Rendering Select with courseD:", courseD)}
                </div>
                <div>
                  <label>Teacher</label>
                  : <select style={{ width: "50%" }} onChange={(e) => setSelectedTeacherId(e.target.value)} value={selectedTeacherId}>
                    <option value="">Select a Teacher</option>
                    {teacherList.map((teacher, i) => (
                      <option value={teacher.teacherId} key={i}>
                        {teacher.teacherName}
                      </option>
                    ))}
                  </select>
                  {teacherSelected && !selectedTeacherId && (
                    <div style={{ color: "red", marginLeft: "-73px", display: "flex", justifyContent: "center" }}>Please select a teacher</div>
                  )}
                </div>


              </div>

            ) : (
              <div>No demo courses available to create packages</div>
            )}    </>
        )}


      </Modal>
      <Modal
        title=""
        visible={isTimeModalVisible}
        onOk={handleTimeMOdalOk}
        onCancel={handleTimeModalCancel}
        okText=" Update"
      >
        {loading ? (
          <Spin size="large" style={{ display: "flex", justifyContent: "center" }} />
        ) : (
          <>
            {timeCourseD && timeCourseD.length > 0 ? (
              <div>
                <div style={{ fontWeight: "bold" }}><label>Student TimeZone</label> : <span>{props.students.viewprofile.timezone}</span></div>
                <div>
                  <label>Course</label>
                  : <select style={{ width: "50%" }} id="courseDD" onChange={(e) => handleTimeCourseChange(e.target.value)}
                    value={selectedTimeDemoCourseId}>
                    <option value="">Select a course</option>
                    {timeCourseD && timeCourseD.length > 0 && (
                      timeCourseD.map((course, i) => (
                        <option value={course.tagId} key={i}>
                          {course.courseName}
                        </option>
                      ))
                    )}
                  </select>
                  {recomentedTimeCourseDetail ? <div><span><label>Recomented Course: </label> {recomentedTimeCourseDetail.courseName}</span></div> : ""}

                  {courseSelected && !selectedTimeDemoCourseId && (
                    <div style={{ color: "red", marginBottom: "12px", marginLeft: "-73px", display: "flex", justifyContent: "center" }}>
                      Please select a course
                    </div>
                  )}

                  {console.log("Rendering Select with courseD:", timeCourseD)}
                </div>

                <div>
                  <label style={{ width: "100px" }}>Preferred Day</label>
                  : <select
                    onChange={(e) => setFileDay(e.target.value)} value={fileDay}

                    style={{ width: "50%" }}


                  >
                    <option value="">Select Day</option>
                    <option value="Monday">
                      Monday
                    </option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                  </select>

                </div>
                <div>
                  <label style={{ width: "100px" }}>Preferred Time</label>
                  : <select

                    style={{ width: "50%" }}

                    onChange={(e) => setFile(e.target.value)} value={file}
                  >

                    <option value="">Select Time</option>
                    <option value="00:00 AM">
                      00:00 AM
                    </option>
                    <option value="01:00 AM"> 01:00 AM</option>
                    <option value="02:00 AM"> 02:00 AM</option>
                    <option value="03:00 AM"> 03:00 AM</option>
                    <option value="04:00 AM"> 04:00 AM</option>
                    <option value="05:00 AM"> 05:00 AM</option>
                    <option value="06:00 AM"> 06:00 AM</option>
                    <option value="07:00 AM"> 07:00 AM</option>
                    <option value="08:00 AM"> 08:00 AM</option>
                    <option value="09:00 AM"> 09:00 AM</option>
                    <option value="10:00 AM"> 10:00 AM</option>
                    <option value="11:00 AM"> 11:00 AM</option>
                    <option value="12:00 PM"> 12:00 PM</option>
                    <option value="01:00 AM"> 01:00 AM</option>
                    <option value="02:00 PM"> 02:00 PM</option>
                    <option value="03:00 PM"> 03:00 PM</option>
                    <option value="04:00 PM"> 04:00 PM</option>
                    <option value="05:00 PM"> 05:00 PM</option>
                    <option value="06:00 PM"> 06:00 PM</option>
                    <option value="07:00 PM"> 07:00 PM</option>
                    <option value="08:00 PM"> 08:00 PM</option>
                    <option value="09:00 PM"> 09:00 PM</option>
                    <option value="10:00 PM"> 10:00 PM</option>
                    <option value="11:00 PM"> 11:00 PM</option>




                  </select>

                </div>

              </div>

            ) : (
              <div>No demo courses available to create packages</div>
            )}    </>
        )}
      </Modal>
    </div>
    {loading ? (
      <Spin size="large" style={{ display: "flex", justifyContent: "center" }} />
    ) : (
      <>
        {(packageList || []).map((v, index) => (
          <Collapse key={index} accordion style={{ marginTop: '10px' }}>
            <Panel header={
              <div className="panel-header-grid">
                <div style={{ width: "calc(100% - 166px)", display: "inline-block" }}>
                  <span style={{ display: 'inline-block', paddingRight: '10px' }}><b>Course:</b> {v.courseData[0].courseName}</span>
                  <span style={{ display: 'inline-block', paddingRight: '10px' }}><b>Created Date:</b> {dayjs(v.createdOn).format('DD-MMM-YYYY h:mmA')}</span>

                  {v.teacherName && v.teacherId != "3" ? <span style={{ display: 'inline-block', paddingRight: '10px' }}><b>Teacher Name:</b> {v.teacherName}</span> : ""}
                  <Button disabled={v.orderId || !v.active} type='primary' onClick={(e) => { assignTeacher(v, index); e.stopPropagation() }} style={{ display: 'inline-block', marginRight: '10px' }}><b> {v.teacherName && v.teacherId != "3" ? "Change Teacher" : "Assign Teacher"}</b></Button>
                  {v.preferredDayTime ? <span style={{ display: 'inline-block', paddingRight: '10px' }}><b>Preferred Day & Time:</b> {v.preferredDayTime}</span> : ""}

                  {v.orderId ? <span style={{ display: 'inline-block', paddingRight: '10px' }}><b>Status: Payment Done</b></span> : ""}

                  <div style={{display:"inline-block"}}><div className="font currency" style={{ display: 'inline-block', paddingRight: '10px' }}>
                    Expiry At : </div>
                  <div className="currency" style={{ display: 'inline-block', paddingRight: '10px' }}>
                    <input className="form-control" style={{ width: '150px', textAlign: 'right', marginTop: '5px' }}
                      type="date"
                      id="expiry-date"
                      value={v.expiryDate}
                      disabled={!v.active}
                      onChange={(e) => handleExpiryData(e,index)}
                    /></div></div>

                  {/* <span style={{ width: '196px' }}>
                      <span style={{
                        borderRadius: "8px",
                        padding: "10px",
                        cursor: "auto",
                        backgroundColor: "" === "pending" ? "yellow" : ("" === "approved" ? "green" : ""),
                        textTransform: "capitalize",
                        color: "" === "approved" ? "white" : "black",
                        margin: "32px",
                        fontWeight: 600
                      }}>
                        {"" === "pending" ? "Pending" : ("" === "approved" ? "Approved" : "")}
                      </span> 
                    </span>*/}
                </div>
                <div style={{ width: "165px", float: "right" }}>
                  <span style={{ display: 'inline-block', paddingRight: '10px', color: `${v.active ? "green" : "red"}`, marginLeft: "10px" }}><b>{v.active ? "Active" : "Inactive"}</b></span>
                  <Button type='primary' onClick={(e) => { handleCopyLink(v.packageLink); e.stopPropagation() }} style={{ display: 'inline-block', paddingRight: '10px' }}><b>Copy Link</b></Button>
                </div>
              </div>
            } key="1">
              <ul style={{ listStyle: "none", background: "white", overflow: "auto", padding: "0px" }}>
                <li>
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">Plan Name</th>
                        <th className="text-center">Course Level</th>
                        {/* <th className="text-center">Class Count</th> */}
                        <th className="text-center">Special Discount(%)</th>
                        <th className="text-center">Flash Discount(%) <FaInfoCircle title="A flash discount will be automatically applied if the user completes the payment within one hour of the demo's conclusion." /></th>
                        <th className="text-center">Additional Discount(%)</th>
                        <th className="text-center">Price Detail ({v.courseData[0].currency})</th>
                        {v.active && <th></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {v.planList.map((plan, i) => (
                        <tr key={i} className={`${plan.remove ? "removed-item" : ""}`}>
                          <td> <label></label>{plan.plan}</td>
                          {/* <td>{plan.level}</td> */}
                          {/* <td>{plan.classCount}</td> */}
                          <td>{plan.courseIds.map((courseId, j) => (
                            <div>{v.courseData.find(e => e.courseId == courseId).level} <input
                              type="text"
                              name={`classCount${i}`}
                              disabled={!!v.orderId || !v.active || plan.remove}
                              value={plan.sessionCounts[courseId]}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                handleClassCount(newValue, courseId, index, i);
                              }}
                              className="form-control"
                            />
                            </div>))}</td>
                          <td>
                            <label></label>
                            <input
                              type="text"
                              name="specialDiscountPercentage"
                              disabled={!!v.orderId || !v.active || plan.remove}
                              value={plan.specialDiscountPercentage}
                              data-limit={'100'}
                              onChange={(e) => {
                                if (e.target.value === '' || +e.target.value <= +e.target.dataset.limit) {
                                const newValue = e.target.value;
                                handleSpecialDiscountChange(newValue, index, i);
                                }
                              }}
                              className="form-control"
                            /></td>
                          <td> <label></label>
                            <input
                              type="text"
                              name="flashDiscountPercentage"
                              disabled={!(v.classDateTime &&  calculateDifference(v))}
                              data-limit={'100'}
                              value={(v.classDateTime &&  calculateDifference(v)) ? plan.flashDiscountPercentage : 0 || 0}
                              onChange={(e) => {
                                if (e.target.value === '' || +e.target.value <= +e.target.dataset.limit) {
                                  const newValue = e.target.value;
                                  handleFlashDiscountChange(newValue, index, i);
                                }
                              }}
                              className="form-control"
                            /><div></div></td>
                          <td>
                            <label></label>
                            <input
                              type="text"
                              name="additionalDiscountPercentage"
                              disabled={!!v.orderId || !v.active || plan.remove}
                              value={plan.additionalDiscountPercentage}
                              data-limit={'100'}
                              onChange={(e) => {
                                if (e.target.value === '' || +e.target.value <= +e.target.dataset.limit) {
                                const newValue = e.target.value;
                                handleAdditionalDiscountChange(newValue, index, i);
                                }
                              }}
                              className="form-control"
                            />
                          </td>
                          <td style={{ minWidth: "150px", textAlign: "right" }}>
                            <label></label>
                            {plan.courseIds.map((id, i) => (
                              <>
                                <div className='calDiv'><span>{v.courseData.find(e => e.courseId == id).level} = </span> <span className='rSpan'><span title='Class Count'>{plan.sessionCounts[id]}</span> * <span title='Class Price'>{v.courseData.find(e => e.courseId == id).unit_price}</span></span> </div>
                              </>))}
                            <div className='calDiv'>Total = <span style={{ borderTop: "2px solid lightgrey" }} className='rSpan'>{plan.total}</span></div>
                            {plan.specialDiscountPercentage ? <div className='calDiv'>Special Discount = <span className='rSpan'>-{(((+plan.specialDiscountPercentage) / 100) * plan.total).toFixed(2)}</span></div> : ""}
                            {plan.additionalDiscountPercentage ? <div className='calDiv'>Additional Discount = <span className='rSpan'>-{(((+plan.additionalDiscountPercentage) / 100) * plan.total).toFixed(2)}</span></div> : ""}
                            {plan.flashDiscountPercentage && v.classDateTime &&  calculateDifference(v) ?  <div className='calDiv'>Flash Discount = <span className='rSpan'>-{(((+plan.flashDiscountPercentage) / 100) * plan.total).toFixed(2)}</span></div> : ""}
                            {plan.walletAmount ? <div className='calDiv'>Wallet Amount = <span className='rSpan'>-{plan.walletAmount}</span></div> : ""}
                            {plan.walletAmount ? <div className='calDiv'>(My Cash : {v.courseData[0].currency == "INR" ? "₹" : "US$"}{plan.myCash} + Artium Cash : {v.courseData[0].currency == "INR" ? "₹" : "US$"}{plan.artiumCash})</div> : ""}

                            {<div className='calDiv'>Final Amount = <span className='rSpan'><b>{plan.total - ((((+((v.classDateTime &&  calculateDifference(v)) ? plan.flashDiscountPercentage : 0) + +plan.specialDiscountPercentage + +plan.additionalDiscountPercentage) / 100) * plan.total) + plan.walletAmount).toFixed(2)}</b></span></div>}
                            {<div className='calDiv'>Total Discount Applied = <span className='rSpan'>{(((plan.total - (plan.total - ((((+((v.classDateTime &&  calculateDifference(v)) ? plan.flashDiscountPercentage : 0) + +plan.specialDiscountPercentage + +plan.additionalDiscountPercentage) / 100) * plan.total) + plan.walletAmount))) / plan.total) * 100).toFixed(2)}%</span></div>}
                          </td>
                          {v.active ? <td> <label></label><div>{v.planList.filter(e => e.remove).length < (v.planList.length - 1) || plan.remove ? <Button disabled={v.orderId} onClick={() => removePlan(index, i)}>{plan.remove ? <b>Reset</b> : <b style={{ color: "red" }}>Remove</b>}</Button> : ""}</div></td> : ""}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div>
                  {v.active ? <div style={{ display: 'inline-block', paddingRight: '10px' }} className='wallet-txt'><label className='wallet-lbl'><input className='wallet-check' checked={!v.removeWallet} onChange={(e) => handleUseWallet(e,index)} type='checkbox'></input> <span>Use wallet balance</span></label></div>:""}
                  {/* {v.active && walletDetail && (+walletDetail?.myCash || +walletDetail?.totalArtiumCash) ? <div style={{ display: 'inline-block', paddingRight: '10px' }} className='wallet-txt'><label className='wallet-lbl'><input className='wallet-check' checked={!v.removeWallet} onChange={(e) => handleUseWallet(e,index)} type='checkbox'></input> <span>Use wallet balance</span></label></div>:""} */}
                    {/* {!v.teacherList && !v.orderId && v.active ? <Button onClick={() => handleTeacherOpen(index)}>Change Teacher</Button> : ""} */}
                    {v.active ? <> Free Course : <select disabled={v.orderId} value={v.freeCourse?.courseId || ""} onChange={(e) => { addFreeCourse(e, index) }}>
                      <option value="">Select free course</option>
                      {allCourseList && allCourseList.map((course, i) => (
                        <option value={course.paidCourseId} key={i}>
                          {course.courseName}
                        </option>
                      ))}
                    </select></> : ""}
                    {v.active && v.freeCourse ? <> Class Count : <input disabled={v.orderId} type='text' placeholder='Please enter class count' style={{ "border": "1px solid", "borderRadius": "3px", "padding": "0px 5px" }} value={v.freeCourse?.classesToPurchase} onChange={(e) => { addFreeClassCount(e, index) }} />
                    </> : ""}

                  </div>
                  {v.teacherList && (
                    <div style={{ marginTop: "10px" }}>
                      <select
                        style={{ width: "200px", display: "flex" }}
                        onChange={(e) => {
                          const { value } = e.target;
                          const clonePackage = [...[], ...packageList];
                          clonePackage[index].teacherId = value;
                          clonePackage[index].teacherName = packageList[index].teacherList.find(e => e.teacherId == value)?.teacherName;
                          clonePackage[index].courseData.forEach(e => e.teacherId = value);
                          setPackageList(clonePackage);
                          // setPackageList(prePackageList => {
                          //   prePackageList[index].teacherId = value;
                          //   prePackageList[index].teacherName = packageList[index].teacherList.find(e => e.teacherId == value)?.teacherName;
                          //   prePackageList[index].courseData.forEach(e => e.teacherId = value);
                          //   return prePackageList});
                        }}
                        value={v.teacherId}
                      >
                        <option value="">Select a Teacher</option>
                        {v.teacherList.map((teacher, i) => (
                          <option value={teacher.teacherId} key={i}>
                            {teacher.teacherName}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div>
                  </div>
                  <div>
                    {(!v.orderId && v.active) ? <Button disabled={packageLoading} type="primary" style={{ display: "flex", float: "right" }} onClick={() => savePackage(v)}>
                      {packageLoading ? "Updating ..." : "Save"}
                    </Button> : ""}
                  </div>

                </li>
              </ul>
            </Panel>
          </Collapse>
        ))}
        {packageList && packageList.length === 0 && <div style={{ textAlign: "center" }}>No packages found</div>}
      </>
    )}


  </div>
  )

}

export default PackageList